import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
	currentAirdropId: number | null;
};

const initialState: InitialState = {
	currentAirdropId: null,
};

const airdropSlice = createSlice({
	name: 'airdrop',
	initialState,
	reducers: {
		setCurrentAirdropId(
			state,
			action: {
				payload: number | null;
			}
		) {
			state.currentAirdropId = action.payload;
		},
	},
});

export const { setCurrentAirdropId } = airdropSlice.actions;

export const apiAirdropSlice = airdropSlice.reducer;
