import {type JSX, type ComponentType } from 'react';
function _QR(): JSX.Element {
	return (
		<div className="flex flex-col gap-y-6 text-2xl bg-black text-white h-screen	w-screeen justify-center items-center">
			<div>Play on your mobile</div>
			<img
				alt="@TONix_Hub_BOT"
				src="/assets/qr_white.webp"
			/>
		</div>
	  )
}
export const QR: ComponentType = _QR;
