import { memo, type JSX, type ComponentType } from 'react';
import classnames from 'classnames';

import { Frame } from '../svg/Frame.tsx';
import { LoadingLogo } from '../svg/LoadingLogo.tsx';

import { useThemeContext } from '../contexts/theme.tsx';

import type { TelegramWebApps } from 'telegram-webapps';

const intl = {
	en: {
		missingNicknameError:
			'Please set your Nickname in Telegram. TonixHub Mini App does not support anonymous users.',
	},
	ru: {
		missingNicknameError:
			'Пожалуйста установите Никнэйм в Telegram. TonixHUB Mini App не поддерживает анонимных пользователей.',
	},
};

type Props = {
	telegramUser: TelegramWebApps.WebAppUser;
};

function _MissingUsernameWarning({ telegramUser }: Props): JSX.Element {
	const themeContext = useThemeContext();

	return (
		<div
			className={classnames(
				'relative flex h-screen flex-col items-center justify-center gap-8 px-4 py-6',
				{
					'bg-[#10152C]': themeContext.theme === 'dark',
					'bg-white': themeContext.theme !== 'dark',
				}
			)}
		>
			<div className='pointer-events-none absolute top-10 h-[569px] w-[360px]'>
				<Frame />
			</div>

			<div
				className={classnames('w-20', {
					'text-slate-200': themeContext.theme === 'dark',
					'text-slate-900': themeContext.theme !== 'dark',
				})}
			>
				<LoadingLogo />
			</div>

			<p
				className={classnames('px-16 text-center font-bold', {
					'text-slate-200': themeContext.theme === 'dark',
					'text-slate-900': themeContext.theme !== 'dark',
				})}
			>
				{telegramUser.language_code === 'ru'
					? intl.ru.missingNicknameError
					: intl.en.missingNicknameError}
			</p>
		</div>
	);
}

export const MissingUsernameWarning: ComponentType<Props> = memo<Props>(
	_MissingUsernameWarning
);
