import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice.ts';
import { apiFarmingSlice } from './farmingApi.ts';
import { apiAirdropSlice } from './airdropApi.ts';

import {
	// eslint-disable-next-line @typescript-eslint/no-restricted-imports
	useDispatch,
	// eslint-disable-next-line @typescript-eslint/no-restricted-imports
	useSelector,
	type TypedUseSelectorHook,
} from 'react-redux';

export const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		[apiFarmingSlice.reducerPath]: apiFarmingSlice.reducer,
		airdrop: apiAirdropSlice,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			apiSlice.middleware,
			apiFarmingSlice.middleware
		),
});

export type AppDispatch = typeof store.dispatch;

export type GetState = typeof store.getState;

export type ReduxState = ReturnType<typeof store.getState>;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector;
