import { memo, type JSX, type ComponentType } from 'react';

function _LoadingLogo(): JSX.Element {
	return (
		<svg fill='none' viewBox='0 0 114 66'>
			<path
				fill='currentColor'
				d='M105.13 66H8.608C3.853 66 0 62.134 0 57.362c0-2.198.872-4.305 2.42-5.86L53.664.088V17.33L22.763 48.334c-2.058 2.064-2.448 7.118 4.221 7.118H86.41c6.063 0 6.977-4.963 4.975-6.973l-31.05-31.15V.089l51.426 51.599a7.67 7.67 0 0 1 2.237 5.415c0 4.914-3.971 8.898-8.867 8.898Z'
			/>

			<path
				fill='currentColor'
				d='M24.869 55.216c.637.151 1.377.236 2.24.236h59.34c.789 0 1.49-.085 2.112-.236h25.204a7.64 7.64 0 0 1 .235 1.886c0 4.914-3.966 8.898-8.855 8.898H8.76C4.014 66 .164 62.134.164 57.362a8.3 8.3 0 0 1 .284-2.146h24.42Zm64.424-44.059c-.637-.158-1.377-.245-2.24-.245h-59.34c-.789 0-1.49.087-2.112.245H.397a8.191 8.191 0 0 1-.235-1.952C.162 4.12 4.128 0 9.017 0h96.382c4.746 0 8.596 4.002 8.596 8.938 0 .758-.098 1.504-.284 2.222H89.293v-.003Z'
			/>

			<path
				fill='currentColor'
				d='M91.234 17.751c2.058-2.064 2.448-7.119-4.221-7.119H56.999V.088h48.391c4.755 0 8.607 3.865 8.607 8.638a8.305 8.305 0 0 1-2.42 5.86L60.333 66V48.758L91.234 17.75ZM56.999.083H8.867C3.969.083 0 4.067 0 8.982c0 2.031.805 3.98 2.237 5.415l51.427 51.599V48.754l-31.047-31.15c-2.003-2.01-1.092-6.973 4.974-6.973h29.408V.083Z'
			/>

			<path
				fill='currentColor'
				d='m38.29 33.04 15.66-15.71V.085L29.699 24.42'
			/>
		</svg>
	);
}

export const LoadingLogo: ComponentType = memo(_LoadingLogo);
