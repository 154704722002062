import {
	memo,
	useMemo,
	useState,
	type JSX,
	useEffect,
	useContext,
	createContext,
	type Dispatch,
	type ComponentType,
	type SetStateAction,
	type PropsWithChildren,
} from 'react';

import type { TelegramWebApps } from 'telegram-webapps';

type ThemeContextValue = {
	theme: string;
	setTheme: Dispatch<SetStateAction<'dark' | 'light'>>;
};

const ThemeContext = createContext<ThemeContextValue>({
	theme: Telegram.WebApp.colorScheme,
	setTheme: (prevState) => (prevState === 'dark' ? 'light' : 'dark'),
});

export function useThemeContext(): ThemeContextValue {
	return useContext(ThemeContext);
}

function _ThemeProvider({ children }: PropsWithChildren): JSX.Element {
	const [theme, setTheme] = useState<'dark' | 'light'>(
		Telegram.WebApp.colorScheme
	);

	useEffect(() => {
		function themeChanged(this: {
			themeParams: TelegramWebApps.ThemeParams;
			colorScheme: TelegramWebApps.ColorScheme;
		}): void {
			setTheme(this.colorScheme);
		}

		Telegram.WebApp.onEvent('themeChanged', themeChanged);

		return () => {
			Telegram.WebApp.offEvent('themeChanged', themeChanged);
		};
	}, []);

	const value = useMemo<ThemeContextValue>(() => {
		return {
			theme,
			setTheme,
		};
	}, [theme]);

	return (
		<ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
	);
}

export const ThemeProvider: ComponentType<PropsWithChildren> =
	memo<PropsWithChildren>(_ThemeProvider);
