import { memo, type ComponentType, type JSX } from 'react';

function _AirdropDark(): JSX.Element {
	return (
		<svg viewBox='0 0 35 33' fill='none'>
			<path
				d='M17.3333 0C7.776 0 0 7.776 0 17.3333C0 23.6987 3.45794 29.2588 8.58594 32.2734L10.026 30.0312C5.63537 27.4939 2.66667 22.7587 2.66667 17.3333C2.66667 9.24667 9.24667 2.66667 17.3333 2.66667C25.42 2.66667 32 9.24667 32 17.3333C32 22.7587 29.0313 27.4939 24.6406 30.0312L26.0807 32.2734C31.2087 29.2588 34.6667 23.6987 34.6667 17.3333C34.6667 7.776 26.8907 0 17.3333 0ZM17.3333 5.33333C10.7173 5.33333 5.33333 10.7173 5.33333 17.3333C5.33333 21.8187 7.81275 25.733 11.4687 27.7917L12.9089 25.5469C9.98885 23.9682 8 20.88 8 17.3333C8 12.1867 12.1867 8 17.3333 8C22.48 8 26.6667 12.1867 26.6667 17.3333C26.6667 20.88 24.6778 23.9695 21.7578 25.5495L23.1979 27.7917C26.8539 25.7317 29.3333 21.8187 29.3333 17.3333C29.3333 10.7173 23.9493 5.33333 17.3333 5.33333ZM17.3333 10.6667C13.6573 10.6667 10.6667 13.6573 10.6667 17.3333C10.6667 19.94 12.1845 22.1774 14.3698 23.2734L15.8125 21.0312C14.3592 20.4312 13.3333 19.0013 13.3333 17.3333C13.3333 15.128 15.128 13.3333 17.3333 13.3333C19.5387 13.3333 21.3333 15.128 21.3333 17.3333C21.3333 19.0013 20.3062 20.43 18.8516 21.0286L20.2943 23.2734C22.4809 22.1788 24 19.94 24 17.3333C24 13.6573 21.0093 10.6667 17.3333 10.6667ZM17.3333 16C16.9797 16 16.6406 16.1405 16.3905 16.3905C16.1405 16.6406 16 16.9797 16 17.3333C16 17.687 16.1405 18.0261 16.3905 18.2761C16.6406 18.5262 16.9797 18.6667 17.3333 18.6667C17.687 18.6667 18.0261 18.5262 18.2761 18.2761C18.5262 18.0261 18.6667 17.687 18.6667 17.3333C18.6667 16.9797 18.5262 16.6406 18.2761 16.3905C18.0261 16.1405 17.687 16 17.3333 16Z'
				fill='black'
			/>
		</svg>
	);
}

export const AirdropDark: ComponentType = memo(_AirdropDark);
