import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = import.meta.env['VITE_API_URL'];

export const apiSlice = createApi({
	reducerPath: 'v1',
	baseQuery: fetchBaseQuery({ baseUrl }),
	endpoints: function endpoints(builder) {
		return {
			getUser: builder.query({
				query: (token: string | null): string => {
					return `/api/v1/users/${token ?? 'no-token'}`;
				},
			}),
			getRobot: builder.query({
				query: (token: string | null): string => {
					return `/api/v1/toni_robot/${token ?? 'no-token'}`;
				},
			}),
			getPartnersTasks: builder.query({
				query: (token: string | null): string => {
					return `/api/v1/task/get_partners_tasks/${token ?? 'no-token'}`;
				},
			}),
			getNftTasks: builder.query({
				query: (token: string | null): string => {
					return `/api/v1/task/get_nft_tasks/${token ?? 'no-token'}`;
				},
			}),
			getOurTasks: builder.query({
				query: (token: string | null): string => {
					return `/api/v1/task/get_system_tasks/${token ?? 'no-token'}`;
				},
			}),
			getSubscriptionTasks: builder.query({
				query: (token: string | null): string => {
					return `/api/v1/task/get_subscription_tasks/${token ?? 'no-token'}`;
				},
			}),
			getAirDrops: builder.query({
				query: (token: string | null): string => {
					return `/api/v1/get_airdrops/${token ?? 'no-token'}`;
				},
			}),
			getMatrix: builder.query({
				query: (token: string | null | undefined): string => {
					return `/api/v1/matrix_statistic/${token ?? 'no-token'}`;
				},
			}),
			getIsSubscribe: builder.query({
				query: (token: string | null | undefined): string => {
					return `/api/v1/is_subscribe/${token ?? 'no-token'}`;
				},
			}),
			getMatrixShow: builder.query({
				query: (token: string): string => {
					return `/api/v1/matrix/show/${token}`;
				},
			}),
			getCheckNft: builder.query({
				query: (user_id: number | undefined): string => {
					return `/api/v1/check-nft/${user_id ?? 'no-id'}`;
				},
			}),
			// /main_channel/subscription
			getMainChannelSubscription: builder.query({
				query: ({ userId, language}: { userId: number | undefined, language: string | undefined }): string => {
					return `/api/v1/main_channel/subscription/${userId ?? 'no-id'}
					${language
						? language === 'ru'
							? '/ru'
							: '/en'
						: ''
					}`;
				},
			}),
		};
	},
});

export const {
	useGetUserQuery,
	useGetRobotQuery,
	useGetMatrixQuery,
	useGetNftTasksQuery,
	useGetOurTasksQuery,
	useGetAirDropsQuery,
	useGetCheckNftQuery,
	useGetIsSubscribeQuery,
	useGetPartnersTasksQuery,
	useGetSubscriptionTasksQuery,
	useGetMainChannelSubscriptionQuery,
} = apiSlice;
