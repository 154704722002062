import { memo, type JSX, type ComponentType } from 'react';

function _ProfileDark(): JSX.Element {
	return (
		<svg viewBox='0 0 34 34'>
			<path
				d='m16.6667 0c9.2 0 16.6666 7.46667 16.6666 16.6667 0 9.2-7.4666 16.6666-16.6666 16.6666-9.20003 0-16.6667-7.4666-16.6667-16.6666 0-9.20003 7.46667-16.6667 16.6667-16.6667zm-9.9617 22.36c2.44667 3.65 6.12 5.9733 10.2283 5.9733 4.1084 0 7.7817-2.3216 10.2267-5.9733-2.774-2.5921-6.4302-4.0317-10.2267-4.0267-3.7971-.0054-7.45393 1.4343-10.2283 4.0267zm9.9617-7.36c1.326 0 2.5978-.5268 3.5355-1.4645.9377-.9376 1.4645-2.2094 1.4645-3.5355 0-1.32608-.5268-2.59785-1.4645-3.53553-.9377-.93769-2.2095-1.46447-3.5355-1.46447-1.3261 0-2.5979.52678-3.5356 1.46447-.9377.93768-1.4644 2.20945-1.4644 3.53553 0 1.3261.5267 2.5979 1.4644 3.5355.9377.9377 2.2095 1.4645 3.5356 1.4645z'
				fill='#000'
			/>
		</svg>
	);
}

export const ProfileDark: ComponentType = memo(_ProfileDark);
