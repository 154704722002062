import { memo, type JSX, type ComponentType } from 'react';

function _TasksDark(): JSX.Element {
	return (
		<svg viewBox='0 0 30 30'>
			<path
				d='m17.1429 27.2727h10.7142v-2.7272h-10.7142zm-6.4286-10.9091h17.1428v-2.7272h-17.1428zm10.7143-10.90905h6.4285v-2.72728h-6.4285zm8.5714 17.72725v5.4546c0 .3693-.106.6889-.3181.9588-.212.2699-.4632.4048-.7533.4048h-27.85717c-.29018 0-.541296-.1349-.75335-.4048-.212053-.2699-.31808-.5895-.31808-.9588v-5.4546c0-.3693.106027-.6889.31808-.9588.212054-.2699.46317-.4048.75335-.4048h27.85717c.2901 0 .5413.1349.7533.4048.2121.2699.3181.5895.3181.9588zm0-10.9091v5.4546c0 .3693-.106.6889-.3181.9588-.212.2699-.4632.4048-.7533.4048h-27.85717c-.29018 0-.541296-.1349-.75335-.4048-.212053-.2699-.31808-.5895-.31808-.9588v-5.4546c0-.3693.106027-.6889.31808-.9588.212054-.2699.46317-.4048.75335-.4048h27.85717c.2901 0 .5413.1349.7533.4048.2121.2699.3181.5895.3181.9588zm0-10.90906v5.45454c0 .36932-.106.68892-.3181.95881-.212.26989-.4632.40483-.7533.40483h-27.85717c-.29018 0-.541296-.13494-.75335-.40483-.212053-.26989-.31808-.58949-.31808-.95881v-5.45454c0-.369322.106027-.688924.31808-.95881.212054-.269887.46317-.40483.75335-.40483h27.85717c.2901 0 .5413.134943.7533.40483.2121.269886.3181.589488.3181.95881z'
				fill='#000'
			/>
		</svg>
	);
}

export const TasksDark: ComponentType = memo(_TasksDark);
