import CryptoJS from 'crypto-js';

const secret = import.meta.env['VITE_SECRET_KEY'];

export function formatNumberWithSpaces(
	number: number | null | undefined
): string {
	if (number == null || isNaN(number)) {
		return '0';
	}

	return number
		.toFixed(3)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function parseNumberFromSpaces(formattedNumber: string): number {
	if (typeof formattedNumber !== 'string') {
		return NaN;
	}

	// Удаляем все пробелы и преобразуем строку в число
	const parsedNumber = parseFloat(formattedNumber.replace(/\s+/g, ''));

	return isNaN(parsedNumber) ? 0 : parsedNumber;
}

export function createUnixTime(): number {
	const currentDate = new Date();
	const unixTimeMilliseconds = currentDate.getTime();
	const unixTimeSeconds = Math.floor(unixTimeMilliseconds / 1000);

	return unixTimeSeconds;
}

export function base64url(source: CryptoJS.lib.WordArray): string {
	let encodedSource = CryptoJS.enc.Base64.stringify(source);
	encodedSource = encodedSource.replace(/=+$/, '');
	encodedSource = encodedSource.replace(/\+/g, '-');
	encodedSource = encodedSource.replace(/\//g, '_');

	return encodedSource;
}

export function createJWT(payload: unknown): string {
	const header = {
		alg: 'HS256',
		typ: 'JWT',
	};

	const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));

	const encodedHeader = base64url(stringifiedHeader);

	const stringifiedPayload = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
	const encodedPayload = base64url(stringifiedPayload);

	const token = encodedHeader + '.' + encodedPayload;
	const signature = CryptoJS.HmacSHA256(token, secret);
	const encodedSignature = base64url(signature);

	return token + '.' + encodedSignature;
}

type TokenArgs = {
	userId: number;
	username: string;
	first_name: string;
	last_name: string;
	language_code: string;
	robot_id?: number | null | undefined;
	data?: number | undefined;
};

export function generateUserToken({
	userId,
	username,
	first_name,
	last_name,
	language_code,
	robot_id,
	data,
}: TokenArgs): string {
	return createJWT({
		id: userId,
		username,
		robot_id,
		data,
		bio: {
			first_name,
			last_name,
			language: language_code,
		},
	});
}

export function isInvalidStateTransition(
	from: 'wait_mining' | 'mining' | 'charging',
	to: 'wait_mining' | 'mining' | 'charging' | null | undefined
): boolean {
	const RobotStatusStateMachine = {
		wait_mining: 'charging',
		mining: 'wait_mining',
		charging: 'mining',
	};

	if (!to) {
		return false;
	}

	return RobotStatusStateMachine[from] !== to;
}
