import {
	memo,
	useMemo,
	type JSX,
	useContext,
	createContext,
	type ComponentType,
	type PropsWithChildren,
} from 'react';

import { useGetCheckNftQuery } from '../api/apiSlice.ts';

import { useUserContext } from './user.tsx';

import type {
	FetchArgs,
	BaseQueryFn,
	QueryDefinition,
	FetchBaseQueryMeta,
	FetchBaseQueryError,
	QueryActionCreatorResult,
} from '@reduxjs/toolkit/query';
import type { SerializedError } from '@reduxjs/toolkit';

type NftContextValue = {
	checkNft: boolean;
	isCheckNftError: boolean;
	checkNftError?: FetchBaseQueryError | SerializedError | undefined;
	isCheckNftUninitialized: boolean;
	isCheckNftLoading: boolean;
	refetchCheckNft: () => QueryActionCreatorResult<
		QueryDefinition<
			number | undefined,
			BaseQueryFn<
				string | FetchArgs,
				unknown,
				FetchBaseQueryError,
				// eslint-disable-next-line @typescript-eslint/ban-types
				{},
				FetchBaseQueryMeta
			>,
			never,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			any,
			'v1'
		>
	>;
};

const NftContext = createContext<NftContextValue | null>(null);

export function useNftContext(): NftContextValue | null {
	return useContext(NftContext);
}

function _NftProvider({ children }: PropsWithChildren): JSX.Element {
	const userContext = useUserContext();

	const {
		data: checkNft,
		isError: isCheckNftError,
		error: checkNftError,
		isUninitialized: isCheckNftUninitialized,
		isLoading: isCheckNftLoading,
		refetch: refetchCheckNft,
	} = useGetCheckNftQuery(userContext.user?.id, {
		skip:
			typeof userContext.user?.id !== 'number' ||
			userContext.user.id === 0,
	});

	const value = useMemo<NftContextValue>(() => {
		return {
			checkNft: checkNft === true,
			isCheckNftError,
			checkNftError,
			isCheckNftUninitialized,
			isCheckNftLoading,
			refetchCheckNft,
		};
	}, [
		checkNft,
		isCheckNftError,
		checkNftError,
		isCheckNftUninitialized,
		isCheckNftLoading,
		refetchCheckNft,
	]);

	return <NftContext.Provider value={value}>{children}</NftContext.Provider>;
}

export const NftProvider: ComponentType<PropsWithChildren> =
	memo<PropsWithChildren>(_NftProvider);
