import {
	createApi,
	fetchBaseQuery,
	type FetchArgs,
} from '@reduxjs/toolkit/query/react';
import { createUnixTime } from '../utils.ts';

const baseUrl = import.meta.env['VITE_API_URL'];

export const apiFarmingSlice = createApi({
	reducerPath: 'farming',
	baseQuery: fetchBaseQuery({ baseUrl }),
	endpoints: (builder) => ({
		createRobot: builder.mutation({
			query: (userId: number): FetchArgs => {
				return {
					url: '/api/v1/api/clik_farm',
					method: 'POST',
					body: {
						id: userId,
						data: createUnixTime(),
					},
				};
			},
		}),
		startFarming: builder.mutation({
			query: ({
				userId,
				robotId,
			}: {
				userId: number;
				robotId: string;
			}): FetchArgs => {
				return {
					url: '/api/v1/api/clik_farm',
					method: 'POST',
					body: {
						id: userId,
						robot_id: robotId,
						data: createUnixTime(),
					},
				};
			},
		}),
	}),
});

export const { useStartFarmingMutation, useCreateRobotMutation } =
	apiFarmingSlice;
