import { memo, type JSX, type ComponentType } from 'react';

function _NftDark(): JSX.Element {
	return (
		<svg fill='none' viewBox='0 0 35 39'>
			<path
				d='m2.30923 32.475 13.61337 6.1828c1.003.4563 2.1518.4563 3.1577 0l13.6105-6.1828c1.4053-.6382 2.3092-2.0619 2.3092-3.6329v-18.6842c0-1.57097-.9039-2.99466-2.3092-3.63287l-13.6105-6.182824c-1.0059-.456275-2.1547-.456275-3.1577 0l-13.61337 6.182824c-1.402449.63821-2.30923 2.0619-2.30923 3.63287v18.6842c0 1.571.906781 2.9947 2.30923 3.6329zm21.02507-17.8496h7.7791c.5365 0 .9709.436.9709.976 0 .5372-.4344.9732-.9709.9732h-2.9186v6.8268c0 .5372-.4345.9732-.971.9732-.5394 0-.9738-.436-.9738-.9732v-6.8268h-2.9157c-.5365 0-.9709-.436-.9709-.9732 0-.54.4344-.976.9709-.976zm-9.7209.976c0-.54.4344-.976.9709-.976h5.8343c.5365 0 .9709.436.9709.976 0 .5372-.4344.9732-.9709.9732h-4.8634v1.9493h4.8634c.5365 0 .9709.4361.9709.9761s-.4344.9761-.9709.9761h-4.8634v2.9253c0 .5372-.4344.9732-.9709.9732s-.9709-.436-.9709-.9732zm-10.69479 0c0-.4216.26824-.7941.66478-.9269.39653-.1329.83389.0057 1.08464.3407l4.08197 5.4609v-4.8747c0-.54.43444-.976.97384-.976.53646 0 .97096.436.97096.976v7.8c0 .4188-.2683.7913-.6648.9241-.10206.0347-.20411.0491-.30616.0491-.30031 0-.59188-.1386-.77849-.3898l-4.08197-5.4609v4.8775c0 .5372-.43735.9732-.97384.9732s-.97093-.436-.97093-.9732z'
				fill='#000000'
			/>
		</svg>
	);
}

export const NftDark: ComponentType = memo(_NftDark);
