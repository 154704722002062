import {
	memo,
	useState,
	type JSX,
	useEffect,
	useContext,
	createContext,
	type ComponentType,
	type PropsWithChildren,
} from 'react';
import type { TelegramWebApps } from 'telegram-webapps';

import { MissingUsernameWarning } from '../components/MissingUsernameWarning.tsx';

const TelegramUserContext = createContext<TelegramWebApps.WebAppUser>({
	id: 0,
	username: '',
	first_name: '',
	last_name: '',
	language_code: '',
});

export function useTelegramUserContext(): TelegramWebApps.WebAppUser {
	return useContext(TelegramUserContext);
}

function _TelegramUserProvider({
	children,
}: PropsWithChildren): JSX.Element | null {
	const [telegramUser, setTelegramUser] =
		useState<TelegramWebApps.WebAppUser | null>(null);

	useEffect(() => {
		const user = Telegram.WebApp.initDataUnsafe.user;

		if (typeof user !== 'undefined') {
			try {
				Telegram.WebApp.expand();
			} catch (error: unknown) {
				if (error instanceof Error) {
					console.error('Ошибка при расширении телеграма:', error);
				} else if (typeof error === 'string') {
					console.error(
						'Ошибка при расширении телеграма:',
						new Error(error)
					);
				} else {
					console.error(
						'Ошибка при расширении телеграма:',
						new Error(JSON.stringify(error))
					);
				}
			}

			setTelegramUser(user);
		}
	}, []);

	if (
		telegramUser !== null &&
		(typeof telegramUser.username !== 'string' ||
			telegramUser.username === '')
	) {
		return <MissingUsernameWarning telegramUser={telegramUser} />;
	}

	// return (
	// 	<div className='flex h-screen flex-col items-center justify-center gap-4 px-8'>
	// 		<div className='w-20'>
	// 			<LoadingLogo />
	// 		</div>

	// 		<p className='text-center font-typingrad text-red-700'>
	// 			В данный момент ведутся технические работы. О восстановлении
	// 			работы TONixHUB мы оповестим вас в нашем Телеграм канале.
	// 		</p>
	// 	</div>
	// );

	return telegramUser === null ? null : (
		<TelegramUserContext.Provider value={telegramUser}>
			{children}
		</TelegramUserContext.Provider>
	);
}

export const TelegramUserProvider: ComponentType<PropsWithChildren> =
	memo<PropsWithChildren>(_TelegramUserProvider);
