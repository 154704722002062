import { memo, useEffect, type JSX, type ComponentType } from 'react';
import classnames from 'classnames';

import { Frame } from '../svg/Frame';

import { LoadingLogo } from '../svg/LoadingLogo';

import { useThemeContext } from '../contexts/theme';
import { useLanguageContext } from '../contexts/language';
import { useMainChannelSubscriptionContext } from '../contexts/main-channel-subscription';

const intl = {
	en: {
		warning:
			'You are not subscribed to our Telegram channel. To continue working with TONixHUB you need to subscribe to our Telegram channel',
		subscribe: 'Subscribe',
	},
	ru: {
		warning:
			'Вы не подписаны на наш Телеграм канал. Для продолжения работы с TONixHUB вам необходимо подписаться на наш Telegram канал',
		subscribe: 'Подписаться',
	},
} as const;

function _SignUpForTelegramChannel(): JSX.Element {
	const themeContext = useThemeContext();

	const languageContext = useLanguageContext();

	const mainChannelSubscriptionContext = useMainChannelSubscriptionContext();

	useEffect(() => {
		async function handleVisibilityChange(): Promise<void> {
			if (document.visibilityState === 'visible') {
				console.info('User returned to the page');

				if (
					mainChannelSubscriptionContext?.mainChannelSubscription ===
					false
				) {
					try {
						await mainChannelSubscriptionContext
							.refetchMainChannelSubscription()
							.unwrap()
							.catch((error: unknown): void => {
								if (error instanceof Error) {
									console.error(error);
								} else if (typeof error === 'string') {
									console.error(new Error(error));
								} else {
									console.error(
										'Ошибка при обновлении подписки на Телеграм канал:',
										new Error(JSON.stringify(error))
									);
								}
							});
					} catch (error: unknown) {
						if (error instanceof Error) {
							console.error(error);
						} else if (typeof error === 'string') {
							console.error(new Error(error));
						} else {
							console.error(
								'Ошибка при обновлении подписки на Телеграм канал:',
								new Error(JSON.stringify(error))
							);
						}
					}
				}
			}
		}

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener(
				'visibilitychange',
				handleVisibilityChange
			);
		};
	}, [mainChannelSubscriptionContext]);

	return (
		<div
			className={classnames(
				'relative flex h-screen flex-col items-center justify-center gap-8 px-4 py-6',
				{
					'bg-[#10152C]': themeContext.theme === 'dark',
					'bg-white': themeContext.theme !== 'dark',
				}
			)}
		>
			<div className='pointer-events-none absolute top-10 h-[569px] w-[360px]'>
				<Frame />
			</div>

			<div
				className={classnames('w-20', {
					'text-slate-200': themeContext.theme === 'dark',
					'text-slate-900': themeContext.theme !== 'dark',
				})}
			>
				<LoadingLogo />
			</div>

			<p
				className={classnames('px-16 text-center font-bold', {
					'text-slate-200': themeContext.theme === 'dark',
					'text-slate-900': themeContext.theme !== 'dark',
				})}
			>
				{intl[languageContext].warning}
			</p>

			<a
				target='_blank'
				href={
					languageContext === 'ru'
						? 'https://t.me/TONixHubInfo'
						: 'https://t.me/TONixHubInfo_EN'
				}
				rel='noreferrer noopener nofollow'
				className={classnames(
					'flex h-10 w-[220px] items-center justify-center rounded-lg bg-[#E8F6FF] px-4 text-sm font-bold underline shadow-md',
					'button-subscribe-telegram',
					{
						'text-white': themeContext.theme === 'dark',
						'text-black': themeContext.theme !== 'dark',
					}
				)}
			>
				{intl[languageContext].subscribe}
			</a>
		</div>
	);
}

export const SignUpForTelegramChannel: ComponentType = memo(
	_SignUpForTelegramChannel
);
