import { memo, type JSX, type ComponentType } from 'react';
import classnames from 'classnames';

import { LoadingLogo } from '../svg/LoadingLogo.tsx';

import { useThemeContext } from '../contexts/theme.tsx';

function _UserLoadingError(): JSX.Element {
	const themeContext = useThemeContext();

	return (
		<div
			className={classnames(
				'flex h-screen flex-col items-center justify-center px-4 py-6',
				{
					'bg-slate-800': themeContext.theme === 'dark',
					'bg-slate-200': themeContext.theme !== 'dark',
				}
			)}
		>
			<div className='w-20'>
				<LoadingLogo />
			</div>

			<p className='text-center font-typingrad text-red-700'>
				Looks like there is an issue with your Telegram Account. Please
				open Tonixhub from{' '}
				<a
					className={classnames('underline', {
						'text-slate-200': themeContext.theme === 'dark',
						'text-slate-800': themeContext.theme !== 'dark',
					})}
					href='https://t.me/TONix_Hub_BOT'
				>
					Telegram TONix_Hub_BOT
				</a>{' '}
				and follow the instructions.
			</p>
		</div>
	);
}

export const UserLoadingError: ComponentType = memo(_UserLoadingError);
