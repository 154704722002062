import {
	memo,
	useState,
	type JSX,
	useEffect,
	useContext,
	createContext,
	type ComponentType,
	type PropsWithChildren,
} from 'react';

import { useTelegramUserContext } from './telegram-user';

type LanguageContextValue = 'ru' | 'en';

const LanguageContext = createContext<LanguageContextValue>('ru');

export function useLanguageContext(): LanguageContextValue {
	return useContext(LanguageContext);
}

function _LanguageProvider({ children }: PropsWithChildren): JSX.Element {
	const telegramUser = useTelegramUserContext();

	const [locale, setLocale] = useState<'ru' | 'en'>('ru');

	useEffect(() => {
		if (telegramUser.language_code === 'ru') {
			setLocale('ru');
		} else {
			setLocale('en');
		}
	}, [telegramUser.language_code]);

	return (
		<LanguageContext.Provider value={locale}>
			{children}
		</LanguageContext.Provider>
	);
}

export const LanguageProvider: ComponentType<PropsWithChildren> =
	memo<PropsWithChildren>(_LanguageProvider);
