import {
	memo,
	useMemo,
	type JSX,
	useContext,
	createContext,
	type PropsWithChildren,
} from 'react';
import type {
	FetchArgs,
	BaseQueryFn,
	QueryDefinition,
	FetchBaseQueryMeta,
	FetchBaseQueryError,
	QueryActionCreatorResult,
} from '@reduxjs/toolkit/query';
import type { SerializedError } from '@reduxjs/toolkit';

import { useGetIsSubscribeQuery } from '../api/apiSlice.ts';

import { useUserContext } from './user.tsx';

type SubscribeContextValue = {
	isSubscribe: boolean;
	refetchIsSubscribe: () => QueryActionCreatorResult<
		QueryDefinition<
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			any,
			BaseQueryFn<
				string | FetchArgs,
				unknown,
				FetchBaseQueryError,
				// eslint-disable-next-line @typescript-eslint/ban-types
				{},
				FetchBaseQueryMeta
			>,
			never,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			any,
			'v1'
		>
	>;
	isIsSubscribeLoading: boolean;
	isIsSubscribeLoadingError: boolean;
	isSubscribeIsUninitialized: boolean;
	isSubscribeLoadingError: FetchBaseQueryError | SerializedError | undefined;
};

const SubscribeContext = createContext<SubscribeContextValue | null>(null);

export function useSubscribeContext(): SubscribeContextValue | null {
	return useContext(SubscribeContext);
}

function _SubscribeProvider({ children }: PropsWithChildren): JSX.Element {
	const userContext = useUserContext();

	const {
		data: isSubscribe,
		refetch: refetchIsSubscribe,
		error: isSubscribeLoadingError,
		isLoading: isIsSubscribeLoading,
		isError: isIsSubscribeLoadingError,
		isUninitialized: isSubscribeIsUninitialized,
	} = useGetIsSubscribeQuery(userContext.token, {
		skip: userContext.token === '',
	});

	const value = useMemo<SubscribeContextValue>(() => {
		return {
			isSubscribe,
			refetchIsSubscribe,
			isIsSubscribeLoading,
			isSubscribeLoadingError,
			isIsSubscribeLoadingError,
			isSubscribeIsUninitialized,
		};
	}, [
		isSubscribe,
		refetchIsSubscribe,
		isIsSubscribeLoading,
		isSubscribeLoadingError,
		isIsSubscribeLoadingError,
		isSubscribeIsUninitialized,
	]);

	return (
		<SubscribeContext.Provider value={value}>
			{children}
		</SubscribeContext.Provider>
	);
}

export const SubscribeProvider = memo(_SubscribeProvider);
