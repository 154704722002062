import {
	memo,
	useMemo,
	useState,
	type JSX,
	useEffect,
	useContext,
	createContext,
	type PropsWithChildren,
} from 'react';
import type {
	FetchArgs,
	BaseQueryFn,
	QueryDefinition,
	FetchBaseQueryMeta,
	FetchBaseQueryError,
	QueryActionCreatorResult,
} from '@reduxjs/toolkit/query';
import type { SerializedError } from '@reduxjs/toolkit';

import { useGetAirDropsQuery } from '../api/apiSlice.ts';

import { useUserContext } from './user.tsx';

import { createJWT, createUnixTime } from '../utils.ts';

export type AirDrop = {
	id: number;
	end_time: number;
	status: 'active' | 'wait' | 'ended';
	title?: string | null | undefined;
	level?: string | null | undefined;
	price?: string | null | undefined;
	descriptions: Record<string, string>;
	icon?: string | null | undefined;
	jeton: Array<string> | string | null;
	winners: Array<Record<string, string>>;
	winner_status: boolean;
	gather_status: boolean;
};

type AirdropsContextValue = {
	airDrops: null | Array<AirDrop> | undefined;
	isAirdropsLoading: boolean;
	isAirdropsError: boolean;
	airdropsError: FetchBaseQueryError | SerializedError | undefined;
	isAirdropsUninitialized: boolean;
	refetchAirdrops: () => QueryActionCreatorResult<
		QueryDefinition<
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			any,
			BaseQueryFn<
				string | FetchArgs,
				unknown,
				FetchBaseQueryError,
				// eslint-disable-next-line @typescript-eslint/ban-types
				{},
				FetchBaseQueryMeta
			>,
			never,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			any,
			'v1'
		>
	>;
};

const AirdropsContext = createContext<AirdropsContextValue | null>(null);

export function useAirdropsContext(): AirdropsContextValue | null {
	return useContext(AirdropsContext);
}

function _AirdropsProvider({ children }: PropsWithChildren): JSX.Element {
	const userContext = useUserContext();

	const [airDropsToken, setAirDropsToken] = useState<string | null>(null);

	useEffect(() => {
		if (typeof userContext.user?.id === 'number') {
			const token = createJWT({
				id: userContext.user.id,
				data: createUnixTime(),
			});

			setAirDropsToken(token);
		}
	}, [userContext.user?.id]);

	// console.info('airDropsToken', airDropsToken);

	const {
		data: airDrops,
		error: airdropsError,
		refetch: refetchAirdrops,
		isError: isAirdropsError,
		isLoading: isAirdropsLoading,
		isUninitialized: isAirdropsUninitialized,
	} = useGetAirDropsQuery(airDropsToken, {
		skip: airDropsToken === null,
	});

	// console.info('airDrops', airDrops);

	const value = useMemo<AirdropsContextValue>(() => {
		return {
			airDrops,
			airdropsError,
			isAirdropsError,
			refetchAirdrops,
			isAirdropsLoading,
			isAirdropsUninitialized,
		};
	}, [
		airDrops,
		airdropsError,
		isAirdropsError,
		refetchAirdrops,
		isAirdropsLoading,
		isAirdropsUninitialized,
	]);

	return (
		<AirdropsContext.Provider value={value}>
			{children}
		</AirdropsContext.Provider>
	);
}

export const AirdropsProvider = memo(_AirdropsProvider);
