import {
	memo,
	useMemo,
	type JSX,
	useContext,
	createContext,
	type PropsWithChildren,
} from 'react';
import type {
	FetchArgs,
	BaseQueryFn,
	QueryDefinition,
	FetchBaseQueryMeta,
	FetchBaseQueryError,
	QueryActionCreatorResult,
} from '@reduxjs/toolkit/query';
import type { SerializedError } from '@reduxjs/toolkit';

import { generateUserToken } from '../utils.ts';

import { useGetUserQuery } from '../api/apiSlice.ts';

import { useTelegramUserContext } from './telegram-user.tsx';

import type { TelegramWebApps } from 'telegram-webapps';

type UserContextValue = {
	user:
		| null
		| {
				id: number;
				username: string;
				first_name: string;
				last_name: string;
				language_code: string;
				bio: {
					first_name: string;
					last_name: string;
					language: string;
					is_active: number;
				};
				refer_id: number | null;
				time_data: {
					registration_data: number;
					last_web_session: number;
				};
				crypto_data?:
					| {
							tonix?:
								| {
										balance: number;
										wallet_type: string | null;
										adress: string | null;
								  }
								| null
								| undefined;
							ton?:
								| {
										balance: number;
										wallet_type: string | null;
										adress: string | null;
								  }
								| null
								| undefined;
					  }
					| null
					| undefined;
				tasks: Record<
					string,
					| string
					| {
							progress: string;
							daily_progress: number;
							last_completed: number;
					  }
				> & { welcome: string };
				daily_rewards: {
					day: number;
					status: string;
				};
				robot_id: string | null;
				profile_lvl: number | null | undefined;
				referrals_robot_counter: number;
				key_2FA: string;
				buycard: boolean;
		  }
		| undefined;
	token: string;
	telegramUser: TelegramWebApps.WebAppUser;
	userError: FetchBaseQueryError | SerializedError | undefined;
	refetchUser:
		| (() => QueryActionCreatorResult<
				QueryDefinition<
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					any,
					BaseQueryFn<
						string | FetchArgs,
						unknown,
						FetchBaseQueryError,
						// eslint-disable-next-line @typescript-eslint/ban-types
						{},
						FetchBaseQueryMeta
					>,
					never,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					any,
					'v1'
				>
		  >)
		| null;
	isUserUninitialized: boolean;
	isUserLoading: boolean;
	isUserLoadingError: boolean;
};

const UserContext = createContext<UserContextValue>({
	user: null,
	token: '',
	telegramUser: {
		id: 0,
		username: '',
		first_name: '',
		last_name: '',
		language_code: '',
	},
	refetchUser: null,
	userError: undefined,
	isUserUninitialized: true,
	isUserLoading: false,
	isUserLoadingError: false,
});

export function useUserContext(): UserContextValue {
	return useContext(UserContext);
}

function _UserProvider({ children }: PropsWithChildren): JSX.Element | null {
	const telegramUser = useTelegramUserContext();

	const userToken = useMemo(() => {
		return generateUserToken({
			userId: telegramUser.id,
			username: telegramUser.username ?? '',
			first_name: telegramUser.first_name,
			last_name: telegramUser.last_name ?? '',
			// https://en.wikipedia.org/wiki/IETF_language_tag
			language_code: telegramUser.language_code ?? 'en',
		});
	}, [
		telegramUser.first_name,
		telegramUser.id,
		telegramUser.language_code,
		telegramUser.last_name,
		telegramUser.username,
	]);

	const {
		data: user,
		isError: isUserLoadingError,
		error: userError,
		isUninitialized: isUserUninitialized,
		isLoading: isUserLoading,
		refetch: refetchUser,
	} = useGetUserQuery(userToken, {
		skip: typeof userToken !== 'string' || userToken === '',
	});

	const value = useMemo<UserContextValue>(() => {
		return {
			user,
			telegramUser,
			token: userToken,
			userError,
			refetchUser,
			isUserLoading,
			isUserLoadingError,
			isUserUninitialized,
		};
	}, [
		user,
		userToken,
		userError,
		refetchUser,
		telegramUser,
		isUserLoading,
		isUserLoadingError,
		isUserUninitialized,
	]);

	return typeof user === 'undefined' ||
		user === null ||
		userToken === '' ? null : (
		<UserContext.Provider value={value}>{children}</UserContext.Provider>
	);
}

export const UserProvider = memo(_UserProvider);
