import { memo, type JSX, type ComponentType } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { AirdropDark } from '../svg/sidebar/AirdropDark.tsx';
import { NftDark } from '../svg/sidebar/NftDark.tsx';
import { PlayDark } from '../svg/sidebar/PlayDark.tsx';
import { TasksDark } from '../svg/sidebar/TasksDark.tsx';
import { ProfileDark } from '../svg/sidebar/ProfileDark.tsx';

function _SideBarFallback(): JSX.Element {
	return (
		<div
			className={classnames(
				'm-auto flex w-[96%] max-w-[600px] items-center justify-around rounded-full bg-center p-3 shadow-lg',
				'custom-sidebar'
			)}
		>
			<Link className='block size-8' to='/'>
				<PlayDark />
			</Link>

			<Link className='block size-8' to='/tasks'>
				<TasksDark />
			</Link>

			<Link className='block size-8' to='/nft'>
				<NftDark />
			</Link>

			<Link className='block size-8' to='/airdrop'>
				<AirdropDark />
			</Link>

			<Link className='block size-8' to='/stats'>
				<ProfileDark />
			</Link>
		</div>
	);
}

export const SideBarFallback: ComponentType = memo(_SideBarFallback);
