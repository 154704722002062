import { memo, useId, type JSX, type ComponentType } from 'react';

const maskType = { 'mask-type': 'luminance' };

function _Frame(): JSX.Element {
	const id_a = useId();
	const id_b = useId();
	const id_c = useId();
	const id_d = useId();
	const id_e = useId();
	const id_f = useId();
	const id_g = useId();
	const id_h = useId();
	const id_i = useId();
	const id_j = useId();
	const id_k = useId();
	const id_l = useId();
	const id_m = useId();
	const id_n = useId();
	const id_o = useId();
	const id_p = useId();
	const id_q = useId();
	const id_r = useId();
	const id_s = useId();
	const id_t = useId();
	const id_u = useId();
	const id_v = useId();
	const id_w = useId();
	const id_x = useId();
	const id_y = useId();
	const id_z = useId();
	const id_A = useId();
	const id_B = useId();
	const id_C = useId();
	const id_D = useId();
	const id_E = useId();
	const id_F = useId();
	const id_G = useId();
	const id_H = useId();
	const id_I = useId();
	const id_J = useId();
	const id_K = useId();
	const id_L = useId();
	const id_M = useId();
	const id_N = useId();
	const id_O = useId();
	const id_P = useId();
	const id_Q = useId();
	const id_R = useId();
	const id_S = useId();
	const id_T = useId();
	const id_U = useId();
	const id_V = useId();
	const id_W = useId();
	const id_X = useId();
	const id_Y = useId();
	const id_Z = useId();
	const id_aa = useId();
	const id_ab = useId();
	const id_ac = useId();
	const id_ad = useId();
	const id_ae = useId();
	const id_af = useId();
	const id_ag = useId();
	const id_ah = useId();
	const id_ai = useId();
	const id_aj = useId();
	const id_ak = useId();
	const id_al = useId();
	const id_am = useId();
	const id_an = useId();
	const id_ao = useId();
	const id_ap = useId();
	const id_aq = useId();
	const id_ar = useId();
	const id_as = useId();
	const id_at = useId();

	return (
		<svg viewBox='0 0 360 569' fill='none'>
			<path
				fill={`url(#${id_a})`}
				fillOpacity='.46'
				d='m1 52 37-36.5h80.5l15-14.5H328l18.5 18.5V94l-5.5 6v116.5l6.5 6 9 9V321L336 342v44l14 14 1 6.5-2 120.5-26 27h-82.5l-6 5.5h-41l-12-11.5H81l-10 10.5H36.5l-11-10L25 422l-5.5-2v-66l-16-17v-89.5l21-20.5v-43l-5.5-6.5V140L1 123V52Z'
			/>

			<path
				fill={`url(#${id_b})`}
				d='M342.299 428.064v-21.273c1.484-.395 2.595-1.733 2.595-3.344a3.474 3.474 0 0 0-6.949 0 3.46 3.46 0 0 0 2.829 3.399v21.539c0 .199.074.394.218.538l17.468 17.447v70.289l-36.248 36.199h-80.804a.752.752 0 0 0-.535.214l-13.947 13.926H29.413l-14.965-14.944v-12.71a.756.756 0 0 0-.753-.752.758.758 0 0 0-.753.752v13.031c0 .195.073.394.217.538l15.412 15.39c.144.144.34.214.536.214h198.159c.199 0 .395-.07.539-.214l13.943-13.926h80.804a.76.76 0 0 0 .539-.214l36.695-36.645a.76.76 0 0 0 .214-.538v-70.916a.752.752 0 0 0-.214-.535l-17.473-17.447-.014-.018Z'
			/>

			<path
				fill={`url(#${id_c})`}
				d='M211.747 558.597a3.456 3.456 0 0 0-3.382-2.702 3.476 3.476 0 0 0-3.475 3.469 3.476 3.476 0 0 0 3.475 3.469c1.647 0 3.024-1.161 3.382-2.698h16.631a.76.76 0 0 0 .753-.752.758.758 0 0 0-.753-.752h-16.631v-.034Z'
			/>

			<path
				fill={`url(#${id_d})`}
				d='M351.105 519.678v-78.207a.756.756 0 0 0-.753-.752c-.41 0-.75.339-.75.752v78.207c0 .413.34.752.75.752a.756.756 0 0 0 .753-.752Z'
			/>

			<mask
				id={id_e}
				width='10'
				height='9'
				x='231'
				y='560'
				maskUnits='userSpaceOnUse'
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={maskType}
			>
				<path
					fill='#fff'
					d='M231.586 560.997h8.508V569h-8.508v-8.003Z'
				/>
			</mask>

			<path
				mask={`url(#${id_e})`}
				fill={`url(#${id_f})`}
				d='M239.422 561.69a.744.744 0 0 0-1.075 0l-5.922 5.917a.739.739 0 0 0 0 1.072c.14.144.339.214.535.214s.395-.07.539-.214l5.923-5.916a.742.742 0 0 0 0-1.073Z'
			/>

			<mask
				id={id_g}
				width='9'
				height='9'
				x='237'
				y='560'
				maskUnits='userSpaceOnUse'
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={maskType}
			>
				<path
					fill='#fff'
					d='M237.258 560.997h8.507V569h-8.507v-8.003Z'
				/>
			</mask>

			<path
				mask={`url(#${id_g})`}
				fill={`url(#${id_h})`}
				d='M245.241 561.69a.744.744 0 0 0-1.075 0l-5.926 5.917a.746.746 0 0 0 0 1.072c.144.144.34.214.539.214.196 0 .392-.07.536-.214l5.926-5.916a.747.747 0 0 0 0-1.073Z'
			/>

			<mask
				id={id_i}
				width='10'
				height='9'
				x='242'
				y='560'
				maskUnits='userSpaceOnUse'
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={maskType}
			>
				<path
					fill='#fff'
					d='M242.929 560.997h8.508V569h-8.508v-8.003Z'
				/>
			</mask>

			<path
				mask={`url(#${id_i})`}
				fill={`url(#${id_j})`}
				d='M251.042 561.69c-.307-.306-.791-.306-1.075 0l-5.926 5.917a.746.746 0 0 0 0 1.072c.144.144.34.214.535.214.2 0 .395-.07.539-.214l5.927-5.916a.749.749 0 0 0 0-1.073Z'
			/>

			<mask
				id={id_k}
				width='9'
				height='9'
				x='249'
				y='560'
				maskUnits='userSpaceOnUse'
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={maskType}
			>
				<path
					fill='#fff'
					d='M249.546 560.997h7.562V569h-7.562v-8.003Z'
				/>
			</mask>

			<path
				mask={`url(#${id_k})`}
				fill={`url(#${id_l})`}
				d='m255.764 561.69-5.923 5.917a.74.74 0 0 0 0 1.072c.144.144.34.214.536.214s.395-.07.539-.214l5.922-5.916c.307-.306.307-.789 0-1.073-.302-.287-.786-.306-1.074 0Z'
			/>

			<path
				fill={`url(#${id_m})`}
				d='M346.988 440.826c.144.144.34.217.535.217.2 0 .395-.073.539-.217a.747.747 0 0 0 0-1.073l-5.926-5.916a.748.748 0 0 0-1.074 0c-.288.306-.303.789 0 1.072l5.926 5.917Z'
			/>
			<path
				fill={`url(#${id_n})`}
				d='m341.062 440.701 5.926 5.92a.77.77 0 0 0 .535.213.77.77 0 0 0 .539-.213.747.747 0 0 0 0-1.073l-5.926-5.92a.75.75 0 0 0-1.074 0c-.288.306-.303.789 0 1.073Z'
			/>
			<path
				fill={`url(#${id_o})`}
				d='m341.062 446.495 5.926 5.917c.144.143.34.213.535.213.2 0 .395-.07.539-.213a.747.747 0 0 0 0-1.073l-5.926-5.916a.743.743 0 0 0-1.074 0c-.288.302-.303.785 0 1.072Z'
			/>
			<path
				fill={`url(#${id_p})`}
				d='m341.062 452.286 5.926 5.917c.144.143.34.214.535.214.2 0 .395-.071.539-.214a.747.747 0 0 0 0-1.073l-5.926-5.916a.743.743 0 0 0-1.074 0c-.288.302-.303.785 0 1.072Z'
			/>
			<path
				fill={`url(#${id_q})`}
				d='M241.733 547.62a.755.755 0 0 0-.753.752c0 .409.339.748.753.748h61.881a.755.755 0 0 0 .753-.748.756.756 0 0 0-.753-.752h-61.881Z'
			/>
			<path
				fill={`url(#${id_r})`}
				d='M318.597 491.165v56.455h-11.778a.756.756 0 0 0-.754.752c0 .409.34.748.754.748h12.546a.755.755 0 0 0 .754-.748v-56.901l27.12-27.064a.747.747 0 0 0 0-1.073c-.306-.288-.79-.306-1.074 0l-27.335 27.297a.75.75 0 0 0-.214.534h-.019Z'
			/>
			<path
				fill={`url(#${id_s})`}
				d='m346.663 508.774-23.465 23.437a.752.752 0 0 0-.214.535v13.211c0 .302.177.59.465.697a.778.778 0 0 0 .824-.159l23.465-23.367a.748.748 0 0 0 .217-.534v-13.282a.735.735 0 0 0-.468-.696.77.77 0 0 0-.824.158Z'
			/>
			<path
				fill={`url(#${id_t})`}
				d='M347.955 489.985a.74.74 0 0 0-.468-.696.743.743 0 0 0-.824.162l-23.465 23.437a.745.745 0 0 0-.214.534v13.212a.738.738 0 0 0 .749.748c.2 0 .396-.07.54-.214l23.465-23.363a.754.754 0 0 0 .217-.534v-13.286Z'
			/>
			<path
				fill={`url(#${id_u})`}
				d='M347.955 470.662a.74.74 0 0 0-.468-.696.77.77 0 0 0-.824.162l-23.465 23.433a.754.754 0 0 0-.214.538v13.212c0 .302.177.59.465.697a.865.865 0 0 0 .284.051c.2 0 .396-.07.54-.214l23.465-23.363a.757.757 0 0 0 .217-.538v-13.282Z'
			/>
			<path
				fill={`url(#${id_v})`}
				d='M350.352 530.8a3.471 3.471 0 0 0-3.471 3.468c0 .679.196 1.305.535 1.84l-22.501 22.471h-83.112a.755.755 0 0 0-.753.748c0 .413.34.752.753.752h83.433c.2 0 .395-.07.539-.213l22.734-22.704a3.427 3.427 0 0 0 1.843.538 3.474 3.474 0 0 0 3.474-3.469 3.474 3.474 0 0 0-3.474-3.468v.037Z'
			/>
			<mask
				id={id_w}
				width='348'
				height='170'
				x='0'
				y='0'
				maskUnits='userSpaceOnUse'
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={maskType}
			>
				<path fill='#fff' d='M0 0h347.852v169.371H0V0Z' />
			</mask>

			<path
				mask={`url(#${id_w})`}
				fill={`url(#${id_x})`}
				d='M18.152 140.847v21.274c-1.485.39-2.596 1.732-2.596 3.339a3.475 3.475 0 0 0 3.475 3.469 3.471 3.471 0 0 0 3.47-3.469 3.454 3.454 0 0 0-2.828-3.395v-21.542a.75.75 0 0 0-.214-.534L1.99 122.542V52.271l36.248-36.217h80.801a.76.76 0 0 0 .539-.218l13.946-13.923h194.005l17.938 17.912v9.046c0 .409.34.748.749.748.414 0 .754-.34.754-.748v-9.352a.769.769 0 0 0-.214-.535L328.39.608a.76.76 0 0 0-.539-.217H133.199a.756.756 0 0 0-.535.217l-13.946 13.923H37.913a.758.758 0 0 0-.535.218L.683 51.412a.755.755 0 0 0-.218.534v70.916c0 .196.074.395.218.538l17.469 17.447Z'
			/>

			<path
				fill={`url(#${id_y})`}
				d='M148.718 10.333a3.46 3.46 0 0 0 3.386 2.698 3.474 3.474 0 0 0 3.471-3.469 3.474 3.474 0 0 0-3.471-3.469c-1.647 0-3.028 1.165-3.386 2.702h-16.627a.755.755 0 0 0-.753.749c0 .413.34.752.753.752h16.627v.037Z'
			/>

			<path
				fill={`url(#${id_z})`}
				d='M9.364 49.23v78.21c0 .41.34.749.753.749.41 0 .75-.339.75-.749V49.23c0-.41-.34-.752-.75-.752a.758.758 0 0 0-.753.752Z'
			/>

			<mask
				id={id_A}
				width='9'
				height='9'
				x='120'
				y='0'
				maskUnits='userSpaceOnUse'
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={maskType}
			>
				<path fill='#fff' d='M120.047 0h8.507v8.003h-8.507V0Z' />
			</mask>

			<path
				mask={`url(#${id_A})`}
				fill={`url(#${id_B})`}
				d='M121.043 7.221a.764.764 0 0 0 .54.214c.195 0 .395-.07.535-.214l5.926-5.916a.746.746 0 0 0 0-1.073.743.743 0 0 0-1.074 0l-5.927 5.917a.748.748 0 0 0 0 1.072Z'
			/>

			<mask
				id={id_C}
				width='9'
				height='9'
				x='114'
				y='0'
				maskUnits='userSpaceOnUse'
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={maskType}
			>
				<path fill='#fff' d='M114.375 0h8.507v8.003h-8.507V0Z' />
			</mask>

			<path
				mask={`url(#${id_C})`}
				fill={`url(#${id_D})`}
				d='M115.228 7.221c.144.144.34.214.536.214.199 0 .395-.07.539-.214l5.922-5.916c.307-.306.307-.789 0-1.073a.74.74 0 0 0-1.071 0l-5.926 5.917a.741.741 0 0 0 0 1.072Z'
			/>

			<mask
				id={id_E}
				width='10'
				height='9'
				x='108'
				y='0'
				maskUnits='userSpaceOnUse'
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={maskType}
			>
				<path fill='#fff' d='M108.704 0h8.507v8.003h-8.507V0Z' />
			</mask>

			<path
				mask={`url(#${id_E})`}
				fill={`url(#${id_F})`}
				d='M109.427 7.221c.144.144.34.214.539.214.196 0 .392-.07.536-.214l5.926-5.916a.746.746 0 0 0 0-1.073.743.743 0 0 0-1.074 0l-5.927 5.917a.748.748 0 0 0 0 1.072Z'
			/>

			<mask
				id={id_G}
				width='9'
				height='9'
				x='103'
				y='0'
				maskUnits='userSpaceOnUse'
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				style={maskType}
			>
				<path fill='#fff' d='M103.032 0h8.507v8.003h-8.507V0Z' />
			</mask>

			<path
				mask={`url(#${id_G})`}
				fill={`url(#${id_H})`}
				d='M104.166 7.435c.196 0 .391-.07.535-.214l5.926-5.916a.746.746 0 0 0 0-1.073.743.743 0 0 0-1.074 0l-5.926 5.917a.747.747 0 0 0 0 1.072.755.755 0 0 0 .539.214Z'
			/>

			<path
				fill={`url(#${id_I})`}
				d='M13.481 128.1a.75.75 0 0 0-1.075 0 .747.747 0 0 0 0 1.073l5.927 5.916c.144.144.34.214.535.214.196 0 .395-.07.54-.214a.746.746 0 0 0 0-1.072L13.48 128.1Z'
			/>

			<path
				fill={`url(#${id_J})`}
				d='m19.407 128.226-5.926-5.917a.744.744 0 0 0-1.075 0c-.288.303-.302.785 0 1.073l5.927 5.916c.144.144.34.214.535.214.196 0 .395-.07.54-.214a.746.746 0 0 0 0-1.072Z'
			/>

			<path
				fill={`url(#${id_K})`}
				d='m19.407 122.416-5.926-5.916a.744.744 0 0 0-1.075 0 .746.746 0 0 0 0 1.072l5.927 5.917c.144.144.34.214.535.214.196 0 .395-.07.54-.214a.747.747 0 0 0 0-1.073Z'
			/>

			<path
				fill={`url(#${id_L})`}
				d='m19.407 116.625-5.926-5.92a.75.75 0 0 0-1.075 0 .747.747 0 0 0 0 1.073l5.927 5.92a.78.78 0 0 0 1.075 0 .747.747 0 0 0 0-1.073Z'
			/>

			<path
				fill={`url(#${id_M})`}
				d='M118.736 21.306c.414 0 .753-.339.753-.748a.755.755 0 0 0-.753-.752h-61.88a.756.756 0 0 0-.754.752c0 .41.34.748.753.748h61.881Z'
			/>

			<path
				fill={`url(#${id_N})`}
				d='M41.872 77.743V21.307H53.65c.41 0 .75-.34.75-.749a.755.755 0 0 0-.75-.752H41.1c-.41 0-.75.34-.75.752v56.897l-27.12 27.083a.747.747 0 0 0 0 1.073.76.76 0 0 0 .535.217c.2 0 .395-.074.54-.217l27.334-27.297a.752.752 0 0 0 .214-.534l.019-.037Z'
			/>

			<path
				fill={`url(#${id_O})`}
				d='M13.267 60.366c.195 0 .391-.07.535-.214l23.469-23.433a.76.76 0 0 0 .214-.539V22.97a.738.738 0 0 0-.465-.697.742.742 0 0 0-.824.162L12.728 45.798a.77.77 0 0 0-.215.538v13.282c0 .302.181.59.466.696.088.037.2.052.288.052Z'
			/>

			<path
				fill={`url(#${id_P})`}
				d='M12.513 78.922c0 .306.181.59.466.697a.77.77 0 0 0 .823-.158l23.469-23.438a.754.754 0 0 0 .214-.538V42.277a.734.734 0 0 0-.465-.696.74.74 0 0 0-.824.158L12.728 65.103a.777.777 0 0 0-.215.538v13.281Z'
			/>

			<path
				fill={`url(#${id_Q})`}
				d='M12.513 98.246c0 .305.181.59.466.7.088.033.2.052.288.052.195 0 .391-.07.535-.214l23.469-23.437a.752.752 0 0 0 .214-.535V61.601a.737.737 0 0 0-.465-.697.735.735 0 0 0-.824.162L12.728 84.43a.767.767 0 0 0-.215.534v13.282Z'
			/>

			<path
				fill={`url(#${id_R})`}
				d='M10.117 38.112a3.474 3.474 0 0 0 3.47-3.469c0-.678-.195-1.305-.535-1.84l22.498-22.47h83.116c.41 0 .749-.34.749-.753a.757.757 0 0 0-.749-.752H35.247a.752.752 0 0 0-.535.218l-22.734 22.7a3.434 3.434 0 0 0-1.846-.535 3.474 3.474 0 0 0-3.471 3.47 3.474 3.474 0 0 0 3.47 3.468l-.014-.037Z'
			/>

			<path
				fill={`url(#${id_S})`}
				d='M193.488 560.079h9.076a.756.756 0 0 0 .754-.752.755.755 0 0 0-.754-.748h-8.769l-10.741-10.727a.756.756 0 0 0-.539-.214H80.93a.747.747 0 0 0-.536.214l-10.276 10.262H36.43l-10.401-10.387V421.001a.754.754 0 0 0-.75-.748.755.755 0 0 0-.753.748v127.032c0 .195.074.39.214.534l10.848 10.834c.144.144.34.214.54.214H70.44c.2 0 .395-.07.539-.214l10.272-10.263h100.961l10.741 10.727c.144.144.34.214.535.214Z'
			/>

			<path
				fill={`url(#${id_T})`}
				d='M14.197 232.657c.41 0 .75-.339.75-.749v-45.834c1.325-.376 2.311-1.574 2.311-3.005 0-1.714-1.414-3.129-3.135-3.129a3.147 3.147 0 0 0-3.13 3.129 3.12 3.12 0 0 0 2.45 3.038v45.801c0 .41.34.749.754.749Z'
			/>

			<path
				fill={`url(#${id_U})`}
				d='m14.304 252.518-5.8-5.791a.75.75 0 0 0-1.075 0 .747.747 0 0 0 0 1.073l5.587 5.559v20.646c-1.363.339-2.382 1.556-2.382 3.023 0 1.714 1.414 3.126 3.131 3.126 1.721 0 3.135-1.412 3.135-3.126 0-1.467-1.019-2.684-2.381-3.023v-20.949a.754.754 0 0 0-.215-.538Z'
			/>

			<path
				fill={`url(#${id_V})`}
				d='M6.355 422.413a.777.777 0 0 0-.215.538v15c0 .195.074.39.215.534l3.832 3.915c.144.144.34.232.536.232.092 0 .199 0 .288-.051a.763.763 0 0 0 .465-.697V424.47l6.103-6.097a.745.745 0 0 0 .215-.535V355.29a.754.754 0 0 0-.215-.538l-6.066-6.06c-.218-.214-.558-.284-.824-.158a.75.75 0 0 0-.465.696v5.345c0 .196.07.391.214.535l2.56 2.558V415.8l-6.658 6.632.015-.019Z'
			/>

			<path
				fill={`url(#${id_W})`}
				d='m27.246 340.004-19.459-19.43a.75.75 0 0 0-.823-.163.764.764 0 0 0-.465.701v12.78a.76.76 0 0 0 .214.534l19.64 19.611c.14.144.34.214.535.214a.87.87 0 0 0 .303-.052.765.765 0 0 0 .469-.715l-.181-12.961a.763.763 0 0 0-.214-.519h-.019Zm-19.245-6.418v-10.635l17.938 17.912.144 10.778L8 333.586Z'
			/>

			<path
				fill={`url(#${id_X})`}
				d='M27.46 320.02a.755.755 0 0 0-.214-.519l-19.459-19.43a.742.742 0 0 0-.823-.163.763.763 0 0 0-.465.697v12.784c0 .195.073.391.214.534l19.64 19.611c.14.144.34.214.535.214a.945.945 0 0 0 .303-.052.765.765 0 0 0 .469-.715l-.181-12.961h-.019Zm-19.459-6.937v-10.635L25.94 320.36l.144 10.778L8 313.083Z'
			/>

			<path
				fill={`url(#${id_Y})`}
				d='M27.46 299.514a.755.755 0 0 0-.214-.516L7.787 279.564a.739.739 0 0 0-.823-.158.756.756 0 0 0-.465.696v12.781a.77.77 0 0 0 .214.538l19.64 19.61a.76.76 0 0 0 .535.214.874.874 0 0 0 .303-.055.762.762 0 0 0 .469-.715l-.181-12.961h-.019ZM8.001 292.58v-10.638l17.938 17.915.144 10.778L8 292.58Z'
			/>

			<path
				fill={`url(#${id_Z})`}
				d='M2.847 178.458v65.033c0 .412.34.752.753.752.41 0 .75-.34.75-.752v-65.033c1.362-.339 2.381-1.556 2.381-3.023 0-1.714-1.414-3.126-3.13-3.126-1.721 0-3.136 1.412-3.136 3.126 0 1.467 1.023 2.684 2.382 3.023Z'
			/>

			<path
				fill={`url(#${id_aa})`}
				d='M17.258 534.143c0-1.43-.986-2.629-2.311-3.005v-55.971l5.586-5.559a.752.752 0 0 0 .214-.535v-48.625l5.085-5.076a.754.754 0 0 0 .214-.538v-15.213c1.363-.339 2.382-1.552 2.382-3.019a3.145 3.145 0 0 0-3.131-3.13 3.146 3.146 0 0 0-3.135 3.13c0 1.467 1.023 2.68 2.381 3.019v14.892l-3.777 3.771v-64.711a.752.752 0 0 0-.214-.535l-16.04-16.017v-88.934l20.692-20.665a.764.764 0 0 0 .218-.538l-.074-20.986s-.018-.088-.018-.125l-.052-22.508a.768.768 0 0 0-.214-.535l-14.142-14.122v-31.606a.756.756 0 0 0-.753-.752c-.41 0-.753.34-.753.752v31.909c0 .199.073.394.217.538l14.142 14.122.07 33.393s.019.126.037.196l.034 9.403-20.693 20.665a.752.752 0 0 0-.214.535v89.561c0 .195.07.394.214.538l16.04 16.016v65.91l-5.586 5.559a.752.752 0 0 0-.214.535v48.625L3.098 484.858a.745.745 0 0 0-.214.534v35.432c-1.363.34-2.382 1.556-2.382 3.019a3.146 3.146 0 0 0 3.135 3.13 3.145 3.145 0 0 0 3.131-3.13c0-1.463-1.019-2.679-2.381-3.019v-35.126l9.076-9.046v54.398c-1.396.321-2.452 1.556-2.452 3.037a3.145 3.145 0 0 0 3.13 3.13 3.146 3.146 0 0 0 3.136-3.13l-.019.056Zm-2.311-107.904 4.28-4.272v46.782l-4.28 4.272v-46.782Z'
			/>

			<path
				fill={`url(#${id_ab})`}
				d='M4.53 477.08c.145.144.34.214.536.214.2 0 .395-.07.539-.214l5.369-5.364a.74.74 0 0 0 0-1.072c-.303-.284-.787-.302-1.075 0l-5.368 5.363a.747.747 0 0 0 0 1.073Z'
			/>

			<path
				fill={`url(#${id_ac})`}
				d='M4.53 470.253a.77.77 0 0 0 .536.214c.2 0 .395-.074.539-.214l5.369-5.363a.741.741 0 0 0 0-1.073c-.303-.288-.787-.306-1.075 0l-5.368 5.363a.747.747 0 0 0 0 1.073Z'
			/>

			<path
				fill={`url(#${id_ad})`}
				d='M4.53 463.404c.145.144.34.214.536.214.2 0 .395-.07.539-.214l5.369-5.364a.74.74 0 0 0 0-1.072c-.303-.284-.787-.303-1.075 0l-5.368 5.363a.747.747 0 0 0 0 1.073Z'
			/>

			<path
				fill={`url(#${id_ae})`}
				d='M4.53 456.577c.145.144.34.214.536.214.2 0 .395-.07.539-.214l5.369-5.363a.741.741 0 0 0 0-1.073c-.303-.288-.787-.302-1.075 0l-5.368 5.363a.747.747 0 0 0 0 1.073Z'
			/>

			<path
				fill={`url(#${id_af})`}
				d='M334.405 21.13v126.725c0 .409.339.748.749.748a.754.754 0 0 0 .753-.748V20.809a.766.766 0 0 0-.214-.538L324.845 9.437a.757.757 0 0 0-.539-.214h-34.313c-.2 0-.396.07-.54.214l-10.272 10.262H178.22L167.479 8.972a.753.753 0 0 0-.535-.213h-9.076a.755.755 0 0 0-.753.752c0 .409.339.748.753.748h8.769l10.742 10.727a.755.755 0 0 0 .539.214h101.585c.195 0 .391-.07.535-.214l10.276-10.263h33.689l10.402 10.388v.019Z'
			/>

			<path
				fill={`url(#${id_ag})`}
				d='M346.272 336.163a.754.754 0 0 0-.753.748v45.838c-1.326.373-2.308 1.571-2.308 3.001a3.144 3.144 0 0 0 3.131 3.129 3.145 3.145 0 0 0 3.135-3.129 3.123 3.123 0 0 0-2.456-3.038v-45.801a.754.754 0 0 0-.749-.748Z'
			/>

			<path
				fill={`url(#${id_ah})`}
				d='m346.146 316.301 5.801 5.791c.14.144.339.214.535.214s.395-.07.539-.214a.747.747 0 0 0 0-1.073l-5.586-5.558v-20.647c1.358-.339 2.381-1.556 2.381-3.023 0-1.714-1.414-3.126-3.135-3.126a3.144 3.144 0 0 0-3.131 3.126 3.127 3.127 0 0 0 2.363 3.023v20.953c0 .195.071.39.215.534h.018Z'
			/>

			<path
				fill={`url(#${id_ai})`}
				d='M356.85 324.562a.755.755 0 0 0-.753.748v65.037c-1.359.339-2.378 1.551-2.378 3.019a3.145 3.145 0 0 0 3.131 3.129 3.146 3.146 0 0 0 3.132-3.129c0-1.468-1.02-2.68-2.364-3.019V325.31a.754.754 0 0 0-.749-.748h-.019Z'
			/>

			<path
				fill={`url(#${id_aj})`}
				d='M354.092 146.406a.758.758 0 0 0 .218-.538v-14.996a.758.758 0 0 0-.218-.538l-3.829-3.915a.782.782 0 0 0-.823-.176.75.75 0 0 0-.465.696v17.41l-6.104 6.097a.752.752 0 0 0-.214.535v62.552c0 .195.07.39.214.534l6.067 6.06a.755.755 0 0 0 .539.214.837.837 0 0 0 .284-.052.755.755 0 0 0 .465-.696v-5.345a.752.752 0 0 0-.214-.538l-2.559-2.555v-58.136l6.658-6.65-.019.037Z'
			/>

			<path
				fill={`url(#${id_ak})`}
				d='m333.205 228.816 19.458 19.43a.75.75 0 0 0 .536.214.806.806 0 0 0 .288-.052.761.761 0 0 0 .465-.697v-12.784a.755.755 0 0 0-.218-.534l-19.636-19.611a.768.768 0 0 0-1.307.553l.181 12.961c0 .199.089.394.214.52h.019Zm19.241 6.417v10.635l-17.934-17.911-.144-10.797 18.078 18.055v.018Z'
			/>

			<path
				fill={`url(#${id_al})`}
				d='M332.99 248.803c0 .195.089.39.215.516l19.458 19.433c.141.14.34.214.536.214a.811.811 0 0 0 .288-.055.761.761 0 0 0 .465-.697v-12.78a.758.758 0 0 0-.218-.538l-19.636-19.611a.769.769 0 0 0-1.307.557l.181 12.961h.018Zm19.456 6.933v10.639l-17.934-17.915-.144-10.797 18.078 18.055v.018Z'
			/>

			<path
				fill={`url(#${id_am})`}
				d='M332.99 269.305c0 .196.089.395.215.516l19.458 19.434a.75.75 0 0 0 .536.214.811.811 0 0 0 .288-.055.755.755 0 0 0 .465-.697v-12.78a.75.75 0 0 0-.218-.535l-19.636-19.61a.765.765 0 0 0-1.307.552l.181 12.961h.018Zm19.456 6.934v10.639l-17.934-17.912-.144-10.797 18.078 18.055v.015Z'
			/>
			<path
				fill={`url(#${id_an})`}
				d='M343.211 34.68c0 1.43.982 2.628 2.308 3v55.973l-5.569 5.558a.771.771 0 0 0-.214.539v48.621l-5.084 5.08a.762.762 0 0 0-.214.534v15.213c-1.359.339-2.378 1.556-2.378 3.019a3.145 3.145 0 0 0 3.131 3.13 3.145 3.145 0 0 0 3.131-3.13c0-1.463-1.019-2.68-2.378-3.019v-14.892l3.792-3.79v64.712a.77.77 0 0 0 .214.538l16.04 16.017v88.934l-20.692 20.665a.752.752 0 0 0-.214.535l.07 20.989s.018.107.037.177l.052 22.56a.76.76 0 0 0 .218.538l14.141 14.122v31.606c0 .409.34.748.75.748a.755.755 0 0 0 .753-.748v-31.912a.767.767 0 0 0-.214-.534l-14.142-14.122-.074-33.394s-.018-.181-.051-.251l-.037-9.44 20.692-20.661a.761.761 0 0 0 .214-.539v-89.56a.752.752 0 0 0-.214-.535l-16.036-16.017v-65.909l5.564-5.559a.76.76 0 0 0 .214-.538V94.316l10.365-10.35a.757.757 0 0 0 .218-.54V47.999a3.113 3.113 0 0 0 2.363-3.022c0-1.718-1.4-3.13-3.135-3.13a3.124 3.124 0 0 0-3.131 3.13c0 1.467 1.019 2.68 2.381 3.023v35.126l-9.075 9.06V37.773c1.395-.325 2.451-1.556 2.451-3.041 0-1.714-1.414-3.126-3.135-3.126a3.133 3.133 0 0 0-3.131 3.126l.019-.052Zm2.289 107.9-4.276 4.272V100.07l4.276-4.272v46.782Z'
			/>
			<path
				fill={`url(#${id_ao})`}
				d='M355.939 91.74c-.307-.303-.791-.303-1.075 0l-5.372 5.363a.746.746 0 0 0 0 1.072.762.762 0 0 0 .539.214c.196 0 .395-.07.535-.214l5.373-5.363a.748.748 0 0 0 0-1.073Z'
			/>
			<path
				fill={`url(#${id_ap})`}
				d='M355.939 98.57c-.307-.306-.791-.306-1.075 0l-5.372 5.363a.742.742 0 0 0 0 1.069.765.765 0 0 0 .539.218.75.75 0 0 0 .535-.218l5.373-5.36a.748.748 0 0 0 0-1.072Z'
			/>
			<path
				fill={`url(#${id_aq})`}
				d='M355.939 105.415c-.307-.302-.791-.302-1.075 0l-5.372 5.364a.746.746 0 0 0 0 1.072.761.761 0 0 0 .539.214c.196 0 .395-.07.535-.214l5.373-5.363a.749.749 0 0 0 0-1.073Z'
			/>
			<path
				fill={`url(#${id_ar})`}
				d='M355.939 112.246c-.307-.306-.791-.306-1.075 0l-5.372 5.36a.746.746 0 0 0 0 1.072.765.765 0 0 0 .539.218.75.75 0 0 0 .535-.218l5.373-5.363a.744.744 0 0 0 0-1.069Z'
			/>
			<path
				fill={`url(#${id_as})`}
				d='M179.025 552.018H83.488a.757.757 0 0 0-.535.213l-5.712 5.703a.739.739 0 0 0-.159.822.754.754 0 0 0 .698.464l107.637.697a.739.739 0 0 0 .698-.464.742.742 0 0 0-.163-.822l-6.406-6.4a.76.76 0 0 0-.539-.213h.018Z'
			/>
			<path
				fill={`url(#${id_at})`}
				d='M180.96 16.713h95.533a.771.771 0 0 0 .576-.25l5.709-6.4a.763.763 0 0 0 .125-.822.74.74 0 0 0-.698-.446H174.569a.739.739 0 0 0-.698.465.766.766 0 0 0 .162.822l6.407 6.4a.755.755 0 0 0 .539.213l-.019.018Z'
			/>

			<defs>
				<linearGradient
					id={id_a}
					x1='178.755'
					x2='178.755'
					y1='559.484'
					y2='.984'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_b}
					x1='186.475'
					x2='186.475'
					y1='568.512'
					y2='399.974'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_c}
					x1='217.011'
					x2='217.011'
					y1='562.833'
					y2='555.895'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_d}
					x1='350.354'
					x2='350.354'
					y1='520.428'
					y2='440.717'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_f}
					x1='235.923'
					x2='235.923'
					y1='568.893'
					y2='561.461'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_h}
					x1='241.741'
					x2='241.741'
					y1='568.893'
					y2='561.461'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_j}
					x1='247.541'
					x2='247.541'
					y1='568.893'
					y2='561.461'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_l}
					x1='253.34'
					x2='253.34'
					y1='568.893'
					y2='561.467'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_m}
					x1='344.565'
					x2='344.565'
					y1='441.043'
					y2='433.61'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_n}
					x1='344.565'
					x2='344.565'
					y1='446.834'
					y2='439.401'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_o}
					x1='344.565'
					x2='344.565'
					y1='452.625'
					y2='445.193'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_p}
					x1='344.565'
					x2='344.565'
					y1='458.416'
					y2='450.984'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_q}
					x1='272.674'
					x2='272.674'
					y1='549.12'
					y2='547.62'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_r}
					x1='326.766'
					x2='326.766'
					y1='549.118'
					y2='463.109'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_s}
					x1='335.47'
					x2='335.47'
					y1='546.708'
					y2='508.559'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_t}
					x1='335.47'
					x2='335.47'
					y1='527.381'
					y2='489.226'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_u}
					x1='335.47'
					x2='335.47'
					y1='508.058'
					y2='469.911'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_v}
					x1='297.44'
					x2='297.44'
					y1='560.078'
					y2='530.762'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_x}
					x1='173.722'
					x2='173.722'
					y1='168.924'
					y2='.386'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_y}
					x1='143.457'
					x2='143.457'
					y1='13.031'
					y2='6.093'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_z}
					x1='10.115'
					x2='10.115'
					y1='128.186'
					y2='48.475'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_B}
					x1='124.544'
					x2='124.544'
					y1='7.435'
					y2='.003'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_D}
					x1='118.727'
					x2='118.727'
					y1='7.435'
					y2='.003'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_F}
					x1='112.928'
					x2='112.928'
					y1='7.435'
					y2='.003'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_H}
					x1='107.127'
					x2='107.127'
					y1='7.435'
					y2='.003'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_I}
					x1='15.907'
					x2='15.907'
					y1='135.303'
					y2='127.873'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_J}
					x1='15.91'
					x2='15.91'
					y1='129.512'
					y2='122.08'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_K}
					x1='15.907'
					x2='15.907'
					y1='123.702'
					y2='116.27'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_L}
					x1='15.907'
					x2='15.907'
					y1='117.911'
					y2='110.478'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_M}
					x1='87.796'
					x2='87.796'
					y1='21.306'
					y2='19.806'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_N}
					x1='33.702'
					x2='33.702'
					y1='105.826'
					y2='19.804'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_O}
					x1='25'
					x2='25'
					y1='60.365'
					y2='22.21'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_P}
					x1='25'
					x2='25'
					y1='79.673'
					y2='41.517'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_Q}
					x1='25'
					x2='25'
					y1='98.996'
					y2='60.841'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_R}
					x1='63.04'
					x2='63.04'
					y1='38.148'
					y2='8.828'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_S}
					x1='113.924'
					x2='113.924'
					y1='560.075'
					y2='420.249'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_T}
					x1='14.125'
					x2='14.125'
					y1='232.655'
					y2='179.938'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_U}
					x1='12.051'
					x2='12.051'
					y1='280.153'
					y2='246.499'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_V}
					x1='11.967'
					x2='11.967'
					y1='442.629'
					y2='348.469'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_W}
					x1='17.079'
					x2='17.079'
					y1='354.25'
					y2='320.351'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_X}
					x1='17.079'
					x2='17.079'
					y1='333.747'
					y2='299.846'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_Y}
					x1='17.079'
					x2='17.079'
					y1='313.244'
					y2='279.343'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_Z}
					x1='3.598'
					x2='3.598'
					y1='244.24'
					y2='172.307'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_aa}
					x1='14.465'
					x2='14.465'
					y1='537.206'
					y2='136.239'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_ab}
					x1='7.754'
					x2='7.754'
					y1='477.294'
					y2='470.424'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_ac}
					x1='7.754'
					x2='7.754'
					y1='470.467'
					y2='463.594'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_ad}
					x1='7.754'
					x2='7.754'
					y1='463.618'
					y2='456.748'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_ae}
					x1='7.754'
					x2='7.754'
					y1='456.791'
					y2='449.919'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_af}
					x1='246.513'
					x2='246.513'
					y1='148.599'
					y2='8.755'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_ag}
					x1='346.344'
					x2='346.344'
					y1='388.878'
					y2='336.161'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_ah}
					x1='348.399'
					x2='348.399'
					y1='322.305'
					y2='288.664'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_ai}
					x1='356.85'
					x2='356.85'
					y1='396.493'
					y2='324.56'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_aj}
					x1='348.484'
					x2='348.484'
					y1='220.338'
					y2='126.19'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_ak}
					x1='343.372'
					x2='343.372'
					y1='248.459'
					y2='214.558'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_al}
					x1='343.372'
					x2='343.372'
					y1='268.965'
					y2='235.063'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
				<linearGradient
					id={id_am}
					x1='343.372'
					x2='343.372'
					y1='289.468'
					y2='255.566'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_an}
					x1='346.014'
					x2='346.014'
					y1='432.646'
					y2='31.594'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_ao}
					x1='352.715'
					x2='352.715'
					y1='98.389'
					y2='91.512'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_ap}
					x1='352.715'
					x2='352.715'
					y1='105.22'
					y2='98.34'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_aq}
					x1='352.715'
					x2='352.715'
					y1='112.065'
					y2='105.188'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_ar}
					x1='352.715'
					x2='352.715'
					y1='118.896'
					y2='112.016'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_as}
					x1='131.599'
					x2='131.599'
					y1='559.917'
					y2='552.017'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>

				<linearGradient
					id={id_at}
					x1='228.395'
					x2='228.395'
					y1='16.713'
					y2='8.795'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#0FF' />
					<stop offset='.089' stopColor='#00F8FF' />
					<stop offset='.216' stopColor='#00E5FF' />
					<stop offset='.367' stopColor='#00C6FF' />
					<stop offset='.535' stopColor='#009BFF' />
					<stop offset='.718' stopColor='#0064FF' />
					<stop offset='.91' stopColor='#02F' />
					<stop offset='1' stopColor='#00F' />
				</linearGradient>
			</defs>
		</svg>
	);
}

export const Frame: ComponentType = memo(_Frame);
