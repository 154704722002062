import { memo, lazy, Suspense, type JSX, type ComponentType } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Fallback from './pages/Fallback.tsx';

const Home = lazy(() => {
	return import('./pages/Home.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Home:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading Home:', new Error(error));
		} else {
			console.error(
				'Error loading Home:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const Tasks = lazy(() => {
	return import('./pages/Tasks.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Tasks:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading Tasks:', new Error(error));
		} else {
			console.error(
				'Error loading Tasks:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const Profile = lazy(() => {
	return import('./pages/Profile.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Profile:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading Profile:', new Error(error));
		} else {
			console.error(
				'Error loading Profile:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const AirDrop = lazy(() => {
	return import('./pages/AirDrop.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading AirDrop:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading AirDrop:', new Error(error));
		} else {
			console.error(
				'Error loading AirDrop:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const Nft = lazy(() => {
	return import('./pages/Nft.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Nft:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading Nft:', new Error(error));
		} else {
			console.error(
				'Error loading Nft:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const BuySubscribe = lazy(() => {
	return import('./pages/BuySubscribe.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Buy Subscribe:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading Buy Subscribe:', new Error(error));
		} else {
			console.error(
				'Error loading Buy Subscribe:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const ChooseSubscribe = lazy(() => {
	return import('./pages/ChooseSubscribe.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Choose Subscribe:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading Choose Subscribe:', new Error(error));
		} else {
			console.error(
				'Error loading Choose Subscribe:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const ChooseTonixCard = lazy(() => {
	return import('./pages/ChooseTonixCard.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Choose Tonix Card:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading Choose Tonix Card:', new Error(error));
		} else {
			console.error(
				'Error loading Tonix Card:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const BuyTonixCard = lazy(() => {
	return import('./pages/BuyTonixCard.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Choose Tonix Card:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading Choose Tonix Card:', new Error(error));
		} else {
			console.error(
				'Error loading Tonix Card:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const Stats = lazy(() => {
	return import('./pages/Stats.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Buy Stats:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading Buy Stats:', new Error(error));
		} else {
			console.error(
				'Error loading Buy Stats:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const AirDropOne = lazy(() => {
	return import('./pages/AirDropOne.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Buy AirDropOne:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading Buy AirDropOne:', new Error(error));
		} else {
			console.error(
				'Error loading Buy AirDropOne:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const TermsOfService = lazy(() => {
	return import('./pages/TermsOfService.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Buy Terms Of Service:', error);
		} else if (typeof error === 'string') {
			console.error(
				'Error loading Buy Terms Of Service:',
				new Error(error)
			);
		} else {
			console.error(
				'Error loading Buy Terms Of Service:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

const Support = lazy(() => {
	return import('./pages/Support.tsx').catch((error: unknown) => {
		if (error instanceof Error) {
			console.error('Error loading Buy Support:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading Buy Support:', new Error(error));
		} else {
			console.error(
				'Error loading Buy Support:',
				new Error(JSON.stringify(error))
			);
		}

		window.location.reload();

		return import('./pages/Fallback.tsx');
	});
});

function Router(): JSX.Element {
	return (
		<>
			<Helmet>
				<title>TONixHub</title>
			</Helmet>

			<Suspense
				fallback={
					// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
					<Fallback />
				}
			>
				<Routes>
					<Route
						path='/'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<Home />
						}
					/>
					<Route
						path='/tasks'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<Tasks />
						}
					/>
					<Route
						path='/profile'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<Profile />
						}
					/>
					<Route
						path='/airdrop'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<AirDrop />
						}
					/>
					<Route
						path='/airdrop/:id'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<AirDropOne />
						}
					/>
					<Route
						path='/nft'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<Nft />
						}
					/>
					<Route
						path='/buy-subscribe'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<BuySubscribe />
						}
					/>

					<Route
						path='/choose-subscribe'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<ChooseSubscribe />
						}
					/>

					<Route
						path='/buy-tonix-card'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<BuyTonixCard />
						}
					/>

					<Route
						path='/choose-tonix-card'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<ChooseTonixCard />
						}
					/>

					<Route
						path='/stats'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<Stats />
						}
					/>

					<Route
						path='/terms-of-service'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<TermsOfService />
						}
					/>

					<Route
						path='/support'
						element={
							// eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
							<Support />
						}
					/>

					<Route path='/*' element={<>404</>} />
				</Routes>
			</Suspense>
		</>
	);
}

export default memo(Router) as ComponentType;
