import { memo, type JSX, type ComponentType } from 'react';
import classnames from 'classnames';

import { LoadingLogo } from '../svg/LoadingLogo.tsx';

import { useThemeContext } from '../contexts/theme.tsx';

function _LoadingScreen(): JSX.Element {
	const themeContext = useThemeContext();

	return (
		<div
			className={classnames('flex h-screen items-center justify-center', {
				'bg-[#10152C]': themeContext.theme === 'dark',
				'bg-white': themeContext.theme !== 'dark',
			})}
		>
			<div
				className={classnames('w-20', {
					'text-slate-200': themeContext.theme === 'dark',
					'text-slate-900': themeContext.theme !== 'dark',
				})}
			>
				<LoadingLogo />
			</div>
		</div>
	);
}

export const LoadingScreen: ComponentType = memo(_LoadingScreen);
