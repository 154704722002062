import { memo, type JSX, type ComponentType } from 'react';
import classnames from 'classnames';

import { SideBarFallback } from '../components/SideBarFallback.tsx';

import { useThemeContext } from '../contexts/theme.tsx';

function _Fallback(): JSX.Element {
	const themeContext = useThemeContext();

	return (
		<div
			className={classnames(`relative flex min-h-screen`, {
				'bg-[#10152C] text-white': themeContext.theme === 'dark',
				'bg-white text-black': themeContext.theme !== 'dark',
			})}
		>
			<div className='fixed top-0 flex h-[120px] w-full flex-col' />

			<div
				className={classnames(
					'relative mx-auto mt-[120px] w-full max-w-screen-lg grow overflow-hidden rounded-xl rounded-t-3xl shadow-lg',
					'custom-border-style'
				)}
			>
				<div
					className={classnames('h-3/5 bg-cover bg-center', {
						'custom-background-image-dark-2':
							themeContext.theme === 'dark',
						'custom-background-image-light-2':
							themeContext.theme !== 'dark',
					})}
				>
					<div className='flex h-full flex-col items-center justify-center' />
				</div>
			</div>

			<div className='fixed bottom-3 left-0 z-40 mx-auto w-full'>
				<SideBarFallback />
			</div>
		</div>
	);
}

export default memo(_Fallback) as ComponentType;
