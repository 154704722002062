import '@total-typescript/ts-reset';

import ReactDOM from 'react-dom/client';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { Provider } from 'react-redux';

import { store } from './api/store.ts';

import App from './App.tsx';

import { NftProvider } from './contexts/nft.tsx';
import { UserProvider } from './contexts/user.tsx';
import { ThemeProvider } from './contexts/theme.tsx';
import { LanguageProvider } from './contexts/language.tsx';
import { AirdropsProvider } from './contexts/airdrops.tsx';
// import { BiometryProvider } from './contexts/biometry.tsx';
import { SubscribeProvider } from './contexts/subscribe.tsx';
import { TelegramUserProvider } from './contexts/telegram-user.tsx';
import { MainChannelSubscriptionProvider } from './contexts/main-channel-subscription.tsx';

import './App.css';
import './index.css';
import 'react-circular-progressbar/dist/styles.css';

const url = import.meta.env['VITE_SITE_URL'];
const manifestUrl = import.meta.env['VITE_TON_CONNECT_MANIFEST_URL'];

const tonConnectError = 'TON_CONNECT_SDK';

function isTonConnectSdkError(error: unknown): boolean {
	if (typeof error === 'string') {
		return error.includes(tonConnectError);
	}

	return (
		typeof error === 'object' &&
		error !== null &&
		'message' in error &&
		typeof error.message === 'string' &&
		error.message.includes(tonConnectError)
	);
}

const fetchDynamicallyImportError =
	'Failed to fetch dynamically imported module';

function isFailedToFetchDynamicallyImportedModuleError(
	error: unknown
): boolean {
	if (typeof error === 'string') {
		return error.includes(fetchDynamicallyImportError);
	}

	return (
		typeof error === 'object' &&
		error !== null &&
		'message' in error &&
		typeof error.message === 'string' &&
		error.message.includes(fetchDynamicallyImportError)
	);
}

window.addEventListener('unhandledrejection', (rejection): void => {
	if (isTonConnectSdkError(rejection.reason)) {
		// ignore TonConnect sdk errors, they are handled by sentry
		return;
	}

	if (isFailedToFetchDynamicallyImportedModuleError(rejection.reason)) {
		window.location.reload();
	}
});

// this manifest is used temporarily for development purposes

const root = document.getElementById('root');

if (!root) {
	throw new Error('Root element not found');
}

ReactDOM.createRoot(root).render(
	// <StrictMode>
	<TonConnectUIProvider manifestUrl={manifestUrl}>
		<Provider store={store}>
			<TelegramUserProvider>
				<LanguageProvider>
					<ThemeProvider>
						<UserProvider>
							<MainChannelSubscriptionProvider>
								<SubscribeProvider>
									<NftProvider>
										<AirdropsProvider>
											<App />
										</AirdropsProvider>
									</NftProvider>
								</SubscribeProvider>
							</MainChannelSubscriptionProvider>
						</UserProvider>
					</ThemeProvider>
				</LanguageProvider>
			</TelegramUserProvider>
		</Provider>
	</TonConnectUIProvider>
	// </StrictMode>,
);

window
	.fetch(`${url}/fonts/index.min.css`)
	.then((response: Response): Promise<string> => {
		return response.text();
	})
	.then((content: string): void => {
		const element = document.createElement('style');

		element.onload = (): void => {
			console.info('Font loaded');
		};

		element.onerror = (error: string | Event): void => {
			if (error instanceof Event) {
				console.error('Error loading font:', error);
			} else if (typeof error === 'string') {
				console.error('Error loading font:', new Error(error));
			} else {
				console.error(
					'Error loading font:',
					new Error(JSON.stringify(error))
				);
			}
		};

		element.appendChild(document.createTextNode(content));

		const styles = document.getElementsByTagName('style');

		const firstStyle = styles.length > 0 ? styles[0] : undefined;

		if (
			typeof firstStyle !== 'undefined' &&
			firstStyle.parentNode !== null
		) {
			firstStyle.parentNode.insertBefore(element, firstStyle);
		} else {
			document.head.appendChild(element);
		}

		return;
	})
	.catch((error: unknown): void => {
		if (error instanceof Error) {
			console.error('Error loading font:', error);
		} else if (typeof error === 'string') {
			console.error('Error loading font:', new Error(error));
		} else {
			console.error(
				'Error loading font:',
				new Error(JSON.stringify(error))
			);
		}
	});
