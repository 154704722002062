import { memo, type JSX, type ComponentType } from 'react';

function _PlayDark(): JSX.Element {
	return (
		<svg viewBox='0 0 26 31'>
			<path
				d='m2.83441 30.7205c-.28011.171-.60568.2673-.94103.2784s-.66753-.0634-.960154-.2153c-.29262-.152-.534382-.3755-.698792-.6462-.1644106-.2706-.2451249-.5779-.23329807-.8882v-27.49842c-.01182683-.31027.06888747-.61756.23329807-.888205.16441-.270642.406172-.494191.698792-.646149.292624-.1519571.624804-.226459.960154-.21534097.33535.01111797.66092.10742697.94103.27837297l22.30059 13.749242c.2645.1517.4828.3633.6342.6149.1513.2515.2308.5347.2308.8226s-.0795.571-.2308.8226c-.1514.2516-.3697.4632-.6342.6149z'
				fill='#000'
			/>
		</svg>
	);
}

export const PlayDark: ComponentType = memo(_PlayDark);
